<template>
  <div v-if="isLoaded" class="dashboard-detail">
    <div class="senior-post-opp-back">
      <div class="back-top" @click="handleBack">
        <div>
          <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
        </div>
        <h2>Back</h2>
      </div>
      <hr />
      <div class="senior-help-icon">
        <ion-icon @mouseenter="showHelpContent=true" @mouseleave="showHelpContent=false" name="help-circle-outline" class="big-icon"></ion-icon>
      </div>
      <div  :class="{'senior-help-content-hidden':true, 'senior-help-content-show':showHelpContent}">
        <p>
          Please read the information and resumes of the <br>
          applicants and decide whether to accept or decline. <br>
          Then Contact and follow up with accepted applicants<br>
          by searching their names in Messages. Thank you. :)
        </p>
      </div>
    </div>

    <h3 class="dashboard-detail-text">
      Please make decisions on the following referral requests.
    </h3>
    <div class="button-bar">
      <ul class="dashboard-detail-status-bar">
        <li id="dashboard-detail-body-all">All({{ statusBarNums[0] }})</li>
        <li id="dashboard-detail-body-pending">
          Pending({{ statusBarNums[1] }})
        </li>
        <li id="dashboard-detail-body-accepted">
          Accepted({{ statusBarNums[2] }})
        </li>
        <li id="dashboard-detail-body-declined">
          Declined({{ statusBarNums[3] }})
        </li>
      </ul>
      <div class="filter-btn">
        <button
          :class="{
            'filter-btn-unclicked': !isFiltering,
            'filter-btn-clicked': isFiltering,
          }"
          @click="isFiltering = !isFiltering"
        >
          <h4 v-if="!isFiltering">Show Additional Filters</h4>
          <h4 v-else>Hide Additional Filters</h4>
        </button>
      </div>
    </div>
    <transition name="fade">
      <Filter v-if="isFiltering" @apply-filters="handleFilters" />
    </transition>
    <ul class="dashboard-detail-list">
      <PostOppDetailCard
        v-for="candidate in filteredCandidateData"
        @change-status="updateStatusBar"
        :key="candidate.id"
        :candidate="candidate"
        :oppData="oppData"
      />
    </ul>
    <div v-if="!candidateData[0]" class="default-post-opp-detail">
      <h3>You have not received any requests yet.</h3>
    </div>
    <div class="delete-block">
      <button class="edit-btn" @click="handleEditOpp()">Edit</button>
      <div class="empty-block"></div>
      <button
        v-if="!confirmDecline"
        :class="{
          'delete-btn': true,
          'confirm-delete': confirmDelete,
        }"
        @click="
          if (confirmDelete) {
            handleDeleteOpp();
          }
          confirmDelete = !confirmDelete;
        "
      >
        <p v-if="confirmDelete">Confirm Delete</p>
        <p v-else>Delete</p>
      </button>
    </div>
  </div>
</template>

<script>
import PostOppDetailCard from "@/components/Dashboard/postOppDetailCard.vue";
import Filter from "@/components/Dashboard/filter.vue";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import getPostOppStatus from "@/composables/Opp/getPostOppStatus";
import getOpp from "@/composables/Opp/getOpp";
import deleteOpp from "@/composables/Opp/deleteOpp";
import clearRegistration from "@/composables/Profile/clearRegistration";

export default {
  props: ["id", "data"],
  components: {
    PostOppDetailCard,
    Filter,
  },
  async setup(props) {
    const candidateData = ref(null);
    const filteredCandidateData = ref(null);
    const oppData = ref(null);
    const router = useRouter();
    const statusBarNums = ref([0, 0, 0, 0]);
    const isFiltering = ref(false);
    const confirmDelete = ref(false);
    const isLoaded = ref(false);
    const showHelpContent = ref(false);

    onMounted(async () => {
      await getPostOppStatus(candidateData, props.id);
      await getOpp(oppData, props.id);
      filteredCandidateData.value = candidateData.value;

      candidateData.value.forEach((candidate) => {
        if (candidate.status == "pending") {
          statusBarNums.value[1]++;
        } else if (candidate.status == "accepted") {
          statusBarNums.value[2]++;
        } else {
          statusBarNums.value[3]++;
        }
        statusBarNums.value[0]++;
      });

      isLoaded.value = true;
    });

    const handleBack = () => {
      router.push({ name: "Senior-Dashboard" });
    };

    const updateStatusBar = (statusArr) => {
      statusBarNums.value[0] += statusArr[0];
      statusBarNums.value[1] += statusArr[1];
      statusBarNums.value[2] += statusArr[2];
      statusBarNums.value[3] += statusArr[3];
    };

    const handleEditOpp = () => {
      router.push({ name: "Senior-EditPostOpp", params: { id: props.id } });
    };

    const handleDeleteOpp = async () => {
      await clearRegistration(props.id);
      await deleteOpp(props.id);
      router.push({ name: "Senior-Dashboard" });
    };

    const handleFilters = (filters) => {
      // console.log("handleFilters activated");
      filteredCandidateData.value = candidateData.value;
      if (filters.hasExp) {
        // console.log("hasExp activated");
        filteredCandidateData.value = filteredCandidateData.value.filter(
          (candidate) => {
            if (candidate.experience.length) {
              return true;
            }
          }
        );
      }
      if (filters.gpa) {
        filteredCandidateData.value = filteredCandidateData.value.filter(
          (candidate) => {
            return candidate.GPA >= Number(filters.gpa);
          }
        );
      }
      if (filters.careerTracks) {
        filteredCandidateData.value = filteredCandidateData.value.filter(
          (candidate) => {
            return filters.careerTracks.includes(candidate.careerTrack);
          }
        );
      }
    };

    return {
      isLoaded,
      candidateData,
      filteredCandidateData,
      oppData,
      statusBarNums,
      isFiltering,
      confirmDelete,
      handleBack,
      updateStatusBar,
      handleEditOpp,
      handleDeleteOpp,
      handleFilters,
      showHelpContent,
    };
  },
};
</script>

<style scoped>
.senior-post-opp-back{
  position: relative;
}

.senior-help-icon{
  top:0;
  right:0;
  position: absolute;
  color: grey;
  transition: all 0.25s ease;
}

.senior-help-icon:hover{
  color: black;
}

.senior-help-content-hidden{
  opacity: 0;
  top:0;
  right: 45px;
  position: absolute;
  padding: 1vh 1vw;
  border: 2px black solid;
  border-radius: 1vh;
  background-color: white;
  transition: all 0.5s ease;
}

.senior-help-content-show{
  opacity: 1;
  transition: all 0.5s ease;
}

.dashboard-detail {
  padding: 5vh 5vw;
  width: 100%;
  height: 100vh;
  animation: fadein 1s;
}

.dashboard-detail-text {
  margin: 2vh 0;
}

.button-bar {
  display: flex;
}

.dashboard-detail-status-bar {
  flex: 0 1 60%;
  list-style: none;
  align-items: center;
}

.dashboard-detail-status-bar li {
  display: inline-block;
  margin-right: 2vw;
  text-align: center;
}

#dashboard-detail-status-bar-blank {
  flex: 1 0 auto;
}

#dashboard-detail-filter-btn {
  flex: 0 1 15%;
}

#dashboard-detail-filter-btn button {
  background-color: white;
  color: #cca2d8;
  padding: 0.5vh 1vw;
  border: 0.3vh #cca2d8 solid;
  border-radius: 1vh;
}

.dashboard-detail-list {
  list-style: none;
}

.default-post-opp-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-post-opp-detail h3 {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  margin: 10vh 0 15vh 0;
}

.delete-block {
  display: flex;
}

.empty-block {
  flex: 1 1 auto;
}

.edit-btn {
  flex: 0 0 20%;
  font-size: 120%;
  font-weight: bold;
  height: 6vh;
  margin-left: auto;
  border-width: 3px;
  border-color:#cca2d8;
  color:#cca2d8;
  background-color: white;
  border-radius: 5vh;
  transition: all 0.25s ease;
}

.edit-btn:hover {
  border-color: #bd4edb;
  color: #bd4edb;
  cursor: pointer;
}

.edit-btn:active {
  border-color: #bd4edb;
  color: white;
  background-color: #bd4edb;
  cursor: pointer;
}

.delete-btn {
  flex: 0 0 20%;
  font-size: 120%;
  font-weight: bold;
  height: 6vh;
  margin-left: auto;
  border-color: rgb(252, 91, 91);
  color: rgb(252, 91, 91);
  background-color: white;
  border-radius: 5vh;
  transition: all 0.25s ease;
}

.edit-btn,
.delete-btn {
  min-width: 100px;
}

.delete-btn:hover {
  border-color: red;
  color: red;
  cursor: pointer;
}

.delete-btn p {
  display: inline;
}

.confirm-delete {
  flex: 0 0 200px;
  color: white;
  border-color: rgb(252, 91, 91);
  background-color: rgb(252, 91, 91);
}

.confirm-delete:hover {
  color: white;
  border-color: red;
  background-color: red;
}

.filter-btn {
  flex: 0 1 40%;
  text-align: right;
  transition: all 0.25s ease;
}

.filter-btn-unclicked {
  background-color: white;
  color: var(--purple-l);
  padding: 0.5vh 1vw;
  border: 0.3vh var(--purple-l) solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-unclicked:hover {
  color: var(--purple);
  border-color: var(--purple);
  cursor: pointer;
}

.filter-btn-clicked {
  background-color: #cca2d8;
  color: white;
  padding: 0.5vh 1vw;
  border: 0.3vh #cca2d8 solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-clicked:hover {
  border-color: var(--purple-400);
  background-color: var(--purple-400);
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
